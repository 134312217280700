:root {
  --primary-color: #333;
}

/* Portfolio Filters */
.portfolio-filters {
  padding: 0;
  margin: 0 0 40px 0;
  list-style: none;
  text-align: center;
}

.portfolio-filters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 20px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  margin: 0 4px 8px 4px;
  transition: all 0.3s ease-in-out;
  border-radius: 50px;
}

.portfolio-filters li:hover,
.portfolio-filters li.filter-active {
  color: #fff;
  background: var(--primary-color);
}

/* Portfolio Items */
.portfolio-item {
  position: relative;
  width: calc(33.333% - 30px);
  margin: 15px;
  float: left;
  overflow: hidden;
  border-radius: 8px;
}

.portfolio-item img {
  width: 100%;
  height: auto;
  transition: all 0.6s ease-in-out;
}

.portfolio-item:hover img {
  transform: scale(1.1);
}

.portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: rgba(0, 0, 0, 0.7);
  padding: 15px;
  border-radius: 8px;
}

.portfolio-item:hover .portfolio-info {
  opacity: 1;
  bottom: 20px;
}

.portfolio-item .portfolio-info h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 5px;
}

.portfolio-item .portfolio-info p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  margin-bottom: 0;
}

.portfolio-item .portfolio-info .preview-link,
.portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 40px;
  font-size: 24px;
  top: calc(50% - 18px);
  color: #fff;
  transition: 0.3s;
}

.portfolio-item .portfolio-info .preview-link:hover,
.portfolio-item .portfolio-info .details-link:hover {
  color: var(--primary-color);
}

.portfolio-item .portfolio-info .details-link {
  right: 10px;
}

@media (max-width: 992px) {
  .portfolio-item {
    width: calc(50% - 30px);
  }
}

@media (max-width: 768px) {
  .portfolio-item {
    width: calc(100% - 30px);
  }
}

/* Add these styles */
.isotope-container {
  position: relative;
  width: 100%;
}

/* Typing animation styles */
.typed {
  color: var(--primary-color);
}

.typed-cursor {
  color: var(--primary-color);
}

.navmenu a {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  padding: 10px 20px;
  margin-bottom: 8px;
  transition: 0.3s;
  font-size: 15px;
  border-radius: 50px;
  text-decoration: none;
}

.navmenu a:hover,
.navmenu .active {
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
}

.navmenu .active {
  background: var(--primary-color);
}

.navmenu .navicon {
  font-size: 20px;
  margin-right: 12px;
}

.cv {
  background: var(--primary-color);
  color: #fff;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 15px;
  transition: 0.3s;
  text-decoration: none;
  margin-right: 20px;
}

.cv:hover {
  background: #1b1b1b;
  color: #fff;
}

.line {
  border: 1px solid #90bc3f;
  padding: 50px;
}

.navs {
  color: #fff !important;
  margin-top: 16px;
  position: absolute;
  margin-left: 15px;
}
